<template>
  <card-form>

    <div class="row mt-4">
      <f-selectsearch 
        label="Tabela"
        name="tabelaDominioId"
        ref="tabelaDominio"
        v-model="model.tabelaDominioId"
        :disabled="disabled"
        v-validate="{required: true}" 
        data-vv-as="Tabela"
        :modelErrors="errors"
        :cols="3"
        :searchFunction="pesquisarTabelaDominio"
        @select="addTabelaDominio"
        :min-length="2"
      />
      <f-input 
        name="codigo" 
        :disabled="disabled"
        v-model="model.codigo" 
        v-validate="{required: true}" 
        data-vv-as="Código"
        label="Código"
        :modelErrors="errors"
        :cols="3"
      />
      <f-input 
        name="descricao" 
        :disabled="disabled"
        v-model="model.descricao" 
        v-validate="{required: true}" 
        data-vv-as="Descricao"
        label="Descrição"
        :modelErrors="errors"
        :cols="6"
      />
      <f-switch
        name="ativo"
        :disabled="disabled"
        v-model="model.ativo"
        :cols="12"
        :label="model.ativo ? 'Ativo' : 'Inativo'"
      />
      
      <div class="row" v-if="!disabled">
        <div class="col-sm-6 d-flex justify-content-start">
          <h4 class="mt-3">Convênios</h4>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <label class="d-inline-flex align-items-center">
            Filtrar:
            <b-form-input
              v-model="convenios.filter.filtrar"
              type="search"
              class="form-control form-control-sm ms-2"
              @input="filtrarConvenios"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <ui-table
            id="convenio-convenio"
            :colunas="convenios.colunas"
            :items="conveniosFiltrados"
            :loading="convenios.loading"
            :force-script-ordenacao="true"
          >
            <template #actions="row">
              <b-button
                v-if="!disabled"
                variant="outline-danger"
                small
                title="remover"
                @click="removerConvenio(row.data)"
              >
                <i class="uil uil-multiply icon-size"></i>
              </b-button>
            </template>
            <template #hactions v-if="!disabled">
              <b-button
                variant="outline-success"
                small
                title="adicionar"
                @click="adicionarConvenio()"
              >
                <i class="uil uil-plus"></i>
              </b-button>
            </template>
            <template #renderCell(nome)="data">
              <div v-if="data.item.id > 0">{{ data.value }}</div>
              <f-selectsearch v-else
                name="selconv"
                placeholder="Digite para pesquisar o convênio"
                labelField="nome"
                :searchFunction="pesquisarConvenio"
                @select="selecionarConvenio"
              />
            </template>
            <template #renderCell(valorProcedimento)="data">
              <div v-if="disabled">{{ data.value }}</div>
              <money v-else
                v-model.lazy="data.item.valorProcedimento"
                class="text-end form-control"
                placeholder="Valor do procedimento no convênio"
              />
            </template>
          </ui-table>
        </div>
      </div>
      
    </div>

    <template #footer>
      <button v-if="!disabled" class="btn btn-success px-5" @click="submeter" type="button">{{ model.id == 0 ? 'Criar' : 'Alterar' }}</button>
      <button class="btn btn-info px-5" @click="() => {$router.push({name: 'cadastros.procedimento.lista'})}" type="button">Voltar</button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import { getProcedimento, criarProcedimento, atualizarProcedimento, getAllConvenio, getAllTabelaDominio } from "@/services/procedimento.service";
import { trataErro, trataInsertSucesso, trataUpdateSucesso } from "../../../../utils/tratar.retornos";
import UiTable from '../../../../components/Ui/UiTable.vue';
import FSelectsearch from '@/components/Form/SelectSearch';
import { Money } from 'v-money'

export default {
  components: {
    CardForm,
    UiTable,
    FSelectsearch,
    Money,
  },
  data() {
    return {
      disabled: true,
      model: {
        id: 0,
        codigo: '',
        ativo: true,
        descricao: '',
        convenios: [],
      },
      convenios: {
        filter: {
          filtro: ''
        },
        colunas: [],
        paginacao: {
          pagina: 1,
        },
        loading: true
      },
    };
  },
  computed: {
    conveniosFiltrados() {
      return this.model.convenios.filter((convenio) => {
        return convenio.nome.indexOf(this.convenios.filter.filtro) != -1;
      });
    }
  },
  watch: {
    disabled() {
      this.fixActionsSize();
    }
  },
  mounted() {
    let cols = [
      { key: "nome", label: "Convênio", sortable: true },
      {
        key: "valorProcedimento",
        label: "Valor do procedimento",
        formatter: (value/*, key, item*/) => {
          return this.$options.filters.toCurrency(value);
        },
        sortable: true
      },
    ]

    this.disabled = !this.$route.meta.editavel;
    
    if (!this.disabled) {
      cols.push({ key: "action", label: "Ações", thClass: 'text-center', tdClass: 'text-center text-nowrap' })
    }

    this.convenios.colunas = cols;

    this.fixActionsSize();
    if (this.$route.params.id && this.$route.params.id > 0) {
      getProcedimento(this.$route.params.id).then(({ data }) => {
        this.model = data.resultado;

        this.$refs.tabelaDominio.setInitialValue(this.model.tabelaDominio);
      })
      .catch((e) => 
        trataErro(e, this)
      )
      .finally(() => {
        this.convenios.loading = false;
      });
    } else {
      this.convenios.loading = false;
    }
  },
  methods: {
    async submeter(event) {
      event.preventDefault()
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.model.id == 0) {
          criarProcedimento(this.model).then(() => {
            trataInsertSucesso(this, 'cadastros.procedimento.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        } else {
          atualizarProcedimento(this.model).then(() => {
            trataUpdateSucesso(this, 'cadastros.procedimento.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        }
      }
    },
    fixActionsSize() {
      let actionIdx = -1;
      for(let i = this.convenios.colunas.length - 1; i >= 0; i--) {
        if (this.convenios.colunas[i].key == 'action') {
          actionIdx = i;
          break;
        }
      }

      if (actionIdx != -1) {
        if(this.disabled) {
          this.convenios.colunas[actionIdx].thStyle = 'width: 45px;';
        } else {
          this.convenios.colunas[actionIdx].thStyle = 'width: 85px;';
        }
      }
    },
    addTabelaDominio(tabela_dominio) {
      if (tabela_dominio) {
        this.model.tabelaDominioId = tabela_dominio.id;
      } else {
        this.model.tabelaDominioId = 0;
      }
    },
    adicionarConvenio() {
      for(let i = this.model.convenios.length - 1; i >= 0; i--) {
        if (!this.model.convenios[i].id) {
          this.model.convenios.splice(i, 1);
        }
      }

      this.model.convenios.unshift({ // Coloca no início da lista
        id: 0,
        nome: '',
        valorProcedimento: 0.0,
      });
    },
    filtrarConvenios() {
      this.convenios.paginacao.pagina = 1;
    },
    pesquisarConvenio(query) {
      const idsJaSelecionados = this.model.convenios.map((item) => item.id);

      return getAllConvenio({ filtro: query, ativo: true }).then(response => {
        return { data: response.data.resultado.filter((item) => {
          return idsJaSelecionados.indexOf(item.id) == -1 && item.status; // Não exibir como opção convênios já selecionados
        }) }
      })
    },
    pesquisarTabelaDominio(query) {
      return getAllTabelaDominio({ filtro: query, ativo: true })
    },
    removerConvenio(convenio) {
      this.$swal.fire({
        title: `Remover registro do convenio ${convenio.nome} do convênio?`,
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      }).then(result => {
        if (result.isConfirmed) {
          for(let i = 0, m = this.model.convenios.length; i < m; i++) {
            if (this.model.convenios[i].id == convenio.id) {
              this.model.convenios.splice(i, 1);
              break;
            }
          }
        }
      });
    },
    selecionarConvenio(convenio) {
      if (convenio && convenio.id > 0) {
        let newConvenioIdx = -1;
        for(let i = 0; i < this.model.convenios.length; i++) {
          if (!this.model.convenios[i].id) {
            newConvenioIdx = i;
            break;
          }
        }

        if (newConvenioIdx != -1) { // Seta o convênio procurado dentro da lista de convênios do hospital
          this.model.convenios[newConvenioIdx].id = convenio.id;
          this.model.convenios[newConvenioIdx].nome = convenio.nome;
        }
      }
    },
  },
};
</script>